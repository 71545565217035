<template>
    <div class="index">
        <!-- 头部公共部分 -->
        <header :class="atHome ? 'headerAtHome' : 'headerNotHome'">
            <div class="wraper headerWraper">
                <div class="logo">
                    <img referrerPolicy="no-referrer" :src="`${$store.state.staticUrl}/logo.png`" alt="">
                </div>
                <nav>
                    <a @click="navClick(index, item.path)" :class="{currentNav: index == currentNav}" v-for="(item, index) in nav" :key="`nav-${index}`">{{item.name}}</a>
                </nav>
				<div class="navH5" @click="showNavH5 = !showNavH5">
					<i class="orangeSkyIcon orangeSkyIconcaidan"></i>
				</div>
				<transition name="el-zoom-in-top">
				<div class="navH5Box" v-show="showNavH5">
					<a @click="navClick(index, item.path)" :class="{currentNav: index == currentNav}" v-for="(item, index) in nav" :key="`nav-${index}`">{{item.name}}</a>
				</div>
				</transition>
            </div>
        </header>

        <!-- 主内容区域 -->
        <div class="content" :class="{ isBanner: atHome }">
			<router-view></router-view>
        </div>

        <!-- 底部公共部分 -->
        <footer>
            <div class="footerLogo">
                <img referrerPolicy="no-referrer" :src="`${$store.state.staticUrl}/logo.png`" alt="">
            </div>
            <div class="footerIcp">
                <p>
                    <span>团队合作&nbsp;&nbsp;品牌合作&nbsp;&nbsp;招商冠名&nbsp;&nbsp;场地租赁</span>
                    <span>合作电话：15988495577（邱经理） 18912796877（敬经理）</span>
                </p>
                <p>
                    <span>联系电话：010-59205222</span>丨
                    <span>电子邮件：info@osgh360.com</span>丨
                    <span>地址：北京市朝阳区东直门外小亮马桥安家楼村8号楼210</span>
                </p>
                <p>
                    <span>橙天三六零票务代理有限公司</span>丨
                    <a href="https://beian.miit.gov.cn/" target="_blank">网站备案号：京ICP备2020040773号-1</a>
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=a2oeg.home.0.0.591b23e1XnRsLV&recordcode=11010502042913" target="_blank">网络安全备案</a>
					<a href="https://image.oskylive.com/360/%E7%94%B5%E4%BF%A1-%E6%96%87%E5%AD%97%E6%B0%B4%E5%8D%B0.jpg" target="_blank">增值电信业务经营许可证</a>
					<a href="https://image.oskylive.com/360/%E6%BC%94%E5%87%BA%E8%AE%B8%E5%8F%AF%E8%AF%81-%E6%96%87%E5%AD%97%E6%B0%B4%E5%8D%B0.jpg" target="_blank">演出许可证</a>
                </p>
            </div>
            <div class="footerQrcode">
                <div>
                    <img referrerPolicy="no-referrer" :src="`${$store.state.staticUrl}/wx.png`" alt="">
                    <p>公众号</p>
                </div>
                <div>
                    <img referrerPolicy="no-referrer" :src="`${$store.state.staticUrl}/${mpQrcodeImg}`" alt="">
                    <p>小程序</p>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'index',
        data (){
            return {
                nav: [ // 导航列表
                    { name: '关于我们', path: '/home' },
                    { name: '剧场信息', path: '/theatre' },
                    // { name: '新闻动态', path: '/news' },
                    // { name: '剧场招聘', path: '/recruit' },
                    { name: '在线购票', path: '/movie' },
                    // { name: '橙天会员', path: '/vip' },
                ],
                atHome: false, // 是否在主页，判断是否给导航栏加样式
                currentNav: 0,
				showNavH5: false,
				mpQrcodeImg: process.env.VUE_APP_MP
            }
        },
        mounted (){
            // 判断当前路由
            switch (this.$route.name){
                case 'index':
                    this.$router.push('/home');
                    this.currentNav = 0;
                    this.atHome = true;
                    break;
                case 'home':
                    this.currentNav = 0;
                    this.atHome = true;
                    break;
                case 'theatre':
                case 'theatreDetail':
                    this.currentNav = 1;
                    break;
                case 'news':
                case 'newsDetail':
                    this.currentNav = 2;
                    break;
                case 'recruit':
                    this.currentNav = 3;
                    break;
                case 'movie':
                case 'movieDetail':
                case 'movieSeat':
                case 'movieOrder':
                case 'moviePay':
                case 'moviePayed':
                    this.currentNav = 4;
                    break;
                case 'vip':
                    this.currentNav = 4;
                    break;
            }
        },
        methods: {
            // 跳转导航
            navClick (index, path){
                this.currentNav = index;
				this.showNavH5 = false;

                // 跳转到目标路由
                if(this.$route.path != path){
                    this.atHome = path == '/home' ? true : false;
                    this.$router.push(path);
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .index{
        // 头部
        .headerAtHome{
            width: 100%;
            position: sticky;
            top: 0;
            z-index: 2;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.19) 0%, rgba(0, 0, 0, 0) 100%);
            color: #FFFFFF;
        }
        .headerNotHome{
            position: static;
            z-index: 1;
            background: #101010;
            color: #FFFFFF;
        }
        .headerWraper{
            height: 88px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo{
                img{
                    height: 35px;
                }
            }
			.navH5{
				display: none;
			}
            nav{
                font-size: 14px;
                a{
                    margin: 0 25px;
                    cursor: pointer;
                }
                a.currentNav{
                    color: #EF5523;
                }
            }
        }

        // 主内容区域
        .content{
			position: relative;
			z-index: 1;
        }
        .isBanner{
            width: 100%;
            margin-top: -88px;
        }

        // 底部公共部分
        footer{
            width: 100%;
            padding: 30px 0;
            background: #383838;
            display: flex;
            justify-content: center;
            align-items: center;
            .footerLogo{
                img{
                    height: 40px;
                }
            }
            .footerIcp{
                margin: 0 80px;
                color: #B8B8B8;
                font-size: 14px;
                p{
                    padding: 10px 0;
                    span, a{
                        display: inline-block;
                        margin: 0 10px;
                    }
                    a{
						color: inherit;
                        cursor: pointer;
                        text-decoration: none;
                        transition: color 0.3s;
                    }
                    a:hover{
                        color: #4d8eff;
                    }
                }
            }
            .footerQrcode{
                display: flex;
                justify-content: center;
                align-items: center;
                div{
                    margin: 0 35px;
                    img{
                        width: 110px;
                        height: 110px;
                    }
                    p{
                        text-align: center;
                        color: #B8B8B8;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

	// H5适配
	@media screen and (max-width: 800px){
		.index{
			header{
				nav{
					display: none;
				}
				.navH5{
					display: block !important;
					i{
						font-size: 7vw;
						color: #616161;
					}
				}
				.navH5Box{
					width: 100vw;
					height: 92vh;
					padding-top: 2vh;
					position: fixed;
					top: 8vh;
					left: 0;
					z-index: 99999;
					background: rgba(0, 0, 0, 0.9);
					a{
						display: block;
						text-indent: 5vw;
						padding: 3vw 0;
						color: #FFFFFF;
					}
					a.currentNav{
						color: #EF5523;
					}
				}
			}
			footer{
				width: 100%;
				display: block !important;
				text-align: center;
				.footerIcp{
					display: none;
				}
				.footerQrcode{
					margin-top: 5vw;
					img{
						width: 28vw !important;
						height: 28vw !important;
					}
				}
			}
		}
	}
</style>