/**
 * ajax 封装
 */

const apiHost = process.env.VUE_APP_HOST;
const ajax = {
    get(url) {
        return new Promise((reslove) => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `${apiHost}/${url}`, true);
            xhr.send();
            xhr.onreadystatechange = () => {
                if (xhr.status == 200 && xhr.readyState == 4){
                    reslove(JSON.parse(xhr.response));
                }
            }
        })
    },
    post(url, data = '') {
		return new Promise((reslove) => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `${apiHost}/${url}?${data}`, true);
            xhr.send();
            xhr.onreadystatechange = () => {
                if (xhr.status == 200 && xhr.readyState == 4){
                    reslove(JSON.parse(xhr.response));
                }
            }
        })
    },
	postJson (url, data){
		return new Promise((reslove) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `${apiHost}/${url}`, true);
            xhr.send(data);
            xhr.onreadystatechange = () => {
                if (xhr.status == 200 && xhr.readyState == 4){
                    reslove(JSON.parse(xhr.response));
                }
            }
        })
	}
}
export default ajax;