import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ajax from './ajax';
import tools from './tools';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "swiper/dist/css/swiper.css"

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$ajax = ajax;
Vue.prototype.$tools = tools;


new Vue({
    router,
    store,
    render: function (h) { return h(App) }
}).$mount('#app')
