/**
 * 工具库
 */

const tools = {
	device (){
        let ua = navigator.userAgent.toLowerCase();
        let code = null;
        console.log(ua)
        if(ua.match('android')) code = 101; // android
        else if(ua.match('iphone')) code = 102; // iphone
        else if(ua.match('ipad')) code = 103; // ipad
        else if(ua.match('intel mac')) code = 203; // apple mac
        else if(ua.match('msie')) code = 202; // IE
        else code = 201; // PC
        return code;
    }
}
export default tools;