import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '../views/index.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('../views/home.vue')
            },
            {
                path: '/theatre',
                name: 'theatre',
                component: () => import('../views/theatre.vue')
            },
            {
                path: '/theatreDetail/:id',
                name: 'theatreDetail',
                component: () => import('../views/theatreDetail.vue')
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('../views/news.vue')
            },
            {
                path: '/newsDetail/:id',
                name: 'newsDetail',
                component: () => import('../views/newsDetail.vue')
            },
            {
                path: '/recruit',
                name: 'recruit',
                component: () => import('../views/recruit.vue')
            },
            {
                path: '/movie',
                name: 'movie',
                component: () => import('../views/movie.vue')
            },
            {
                path: '/movieDetail/:id',
                name: 'movieDetail',
                component: () => import('../views/movieDetail.vue')
            },
            {
                path: '/movieSeat/:id',
                name: 'movieSeat',
                component: () => import('../views/movieSeat.vue')
            },
            {
                path: '/movieOrder',
                name: 'movieOrder',
                component: () => import('../views/movieOrder.vue')
            },
            {
                path: '/moviePay/:type',
                name: 'moviePay',
                component: () => import('../views/moviePay.vue')
            },
            {
                path: '/moviePayed/:result?',
                name: 'moviePayed',
                component: () => import('../views/moviePayed.vue')
            },
            {
                path: '/vip',
                name: 'vip',
                component: () => import('../views/vip.vue')
            }
        ]
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('../views/invoice.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    next()
})

export default router
